import React from 'react';
import Layout from '../../components/layout/';
import Breadcrumb from '../../components/breadcrumb';
import styles from '../../styles/LegalDisclaimer.module.css';  // Importando o CSS Module

const LegalDisclaimer = () => {
  return (
    <Layout 
      title="Aviso Legal - Minha Sorte" 
      description="Leia nosso aviso legal para entender como o site coleta e utiliza informações, além de como usamos os anúncios de terceiros, como o Google AdSense." 
      keywords="aviso legal, disclaimer, política de privacidade, Google AdSense, cookies, responsabilidade"
    >
      <div className={styles.legalContainer}>
        <Breadcrumb paths={[
          { href: '/', label: 'Home' },
          { href: '/aviso-legal', label: 'Aviso Legal' },
        ]} />

        <h1 className={styles.legalTitle}>Aviso Legal</h1>
        
        <div className={styles.legalText}>
          <p>Este Aviso Legal descreve as condições e responsabilidades ao utilizar o site <strong>Minha Sorte</strong>. Ao acessar e utilizar este site, você concorda com os termos estabelecidos neste aviso.</p>

          <h2>1. Uso de Cookies</h2>
          <p>Este site utiliza cookies para melhorar a experiência do usuário. Cookies são pequenos arquivos armazenados no seu dispositivo que nos ajudam a entender como você interage com o conteúdo. Ao continuar navegando, você concorda com o uso de cookies.</p>
          <p>Você pode desativar os cookies nas configurações do seu navegador, mas isso pode afetar a funcionalidade do site.</p>

          <h2>2. Anúncios de Terceiros</h2>
          <p>Este site utiliza anúncios de terceiros, incluindo Google AdSense, para exibir anúncios personalizados aos visitantes. Esses anúncios podem usar cookies para coletar informações sobre suas visitas ao site e exibir anúncios relevantes. O Google AdSense pode coletar dados como:</p>
          <ul>
            <li>Tipo de navegador utilizado.</li>
            <li>Sites visitados anteriormente.</li>
            <li>Interações com os anúncios.</li>
          </ul>
          <p>Para mais informações sobre como o Google AdSense coleta e usa dados, consulte a <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer" className={styles.legalLink}>Política de Privacidade do Google</a>.</p>

          <h2>3. Limitação de Responsabilidade</h2>
          <p>O site <strong>Minha Sorte</strong> se esforça para fornecer informações precisas, mas não garante que todos os dados sejam completos ou isentos de erros. O conteúdo exibido, incluindo resultados de loteria e dicas, é fornecido para fins informativos e deve ser utilizado com cautela.</p>
          <p>O site não se responsabiliza por quaisquer perdas ou danos causados pelo uso ou pela confiança nos dados fornecidos, incluindo informações relacionadas a jogos de azar.</p>

          <h2>4. Modificações no Aviso Legal</h2>
          <p>O conteúdo deste aviso legal pode ser alterado a qualquer momento, sem aviso prévio. Recomendamos que você verifique periodicamente esta página para estar ciente de quaisquer atualizações.</p>

          <h2>5. Propriedade Intelectual</h2>
          <p>Todo o conteúdo deste site, incluindo textos, imagens, gráficos, e logos, é protegido por direitos autorais e outros direitos de propriedade intelectual. Nenhum conteúdo pode ser copiado ou redistribuído sem permissão prévia.</p>

          <h2>6. Contato</h2>
          <p>Se você tiver dúvidas sobre este aviso legal ou sobre o uso de seus dados, entre em contato conosco pelo e-mail: <a href="mailto:admin@minhasorte.com.br" className={styles.legalLink}>admin@minhasorte.com.br</a>.</p>
        </div>
      </div>
    </Layout>
  );
};

export default LegalDisclaimer;
