import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './styles/global.css';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import ContactPage from './pages/Contact';
import AboutPage from './pages/AboutUs';
import Home from './pages/Home';
import Contest from './pages/Contest/lotofacil';
import LegalDisclaimer from './pages/LegalDisclaimer';

function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/concurso/:numero" element={<Contest />} />
          <Route path="/lotofacil/concurso/:numero" element={<Contest />} />
          <Route path="/politica-de-privacidade" element={<PrivacyPolicy />} />
          <Route path="/termos-de-uso" element={<TermsOfUse />} />
          <Route path="/contato" element={<ContactPage />} />
          <Route path="/sobre-nos" element={<AboutPage />} />
          <Route path="/aviso-legal" element={<LegalDisclaimer />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;