import React, { lazy, useEffect, useState, Suspense } from 'react';
import styles from '../../styles/CourseAd.module.css'; // Importando o CSS Module
import useIsMobile from '../../hooks/useIsMobile';

interface CourseAd {
  image: string;
  link: string;
  title: string;
  label: string;
  page: string;
  alt: string;
  oldPrice?: number;
  newPrice?: number;
  discountCoupons: string[];
  ratings?: {
    rating: number;
    reviews: number;
    sold: number;
  };
}

interface CourseAdProps {
  title?: string;
  discount?: string;
  ads: CourseAd[];
}

const LazyImage = lazy(() => import('../lazyImage'));

const CourseAd: React.FC<CourseAdProps> = ({ ads, title, discount }) => {
  const [displayAd, setDisplayAd] = useState<CourseAd | null>(null);
  const [randomTitle, setRandomTitle] = useState<string>('');
  const isMobile = useIsMobile();

  const titles = [
    '💸 Renda Extra? Descubra essa Profissão! 💼',
    '💰 Mude de Vida com uma Nova Carreira! 💼',
    '🤑 Ganhos Maiores? Novas Oportunidades! 💼',
    '💵 Quer Ganhar Mais? Mude de Profissão! 💼',
    '💸 Dinheiro? Aprenda a Fazer Renda Extra! 💰',
    '🤑 Dinheiro Rápido: Renda Extra Aqui! 🏦',
    '💵 Quer Mais? Descubra a Renda Extra! 💼',
    '💰 Precisando de Grana? Renda Extra Aqui! 💵',
    '💸 Renda Extra: Encha Seus Bolsos Agora! 💰'
  ];

  useEffect(() => {
    if (ads.length > 0) {
      const randomAdIndex = Math.floor(Math.random() * ads.length);
      setDisplayAd(ads[randomAdIndex]);

      const randomTitleIndex = Math.floor(Math.random() * titles.length);
      setRandomTitle(titles[randomTitleIndex]);
    }
  }, [ads]);

  const truncateTitle = (title: string, length: number = isMobile ? 45 : 40): string => {
    if (title.length > length) {
      return title.substring(0, length) + '...';
    }
    return title;
  };

  const renderStars = (rating: number) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(<span key={i} className={i < rating ? styles.stars : styles.inactiveStars}>★</span>);
    }
    return stars;
  };

  if (!displayAd) {
    return null;
  }

  return (
    <div className={styles.courseAdBanner}>
      <a className={styles.courseAd} href={displayAd.link} target="_blank" rel="noopener noreferrer">
        <h2 className={ styles.courseAdBannerTitle}>{title ? title : randomTitle}</h2>
        <Suspense fallback={<div>Loading...</div>}>
          <LazyImage src={displayAd.image} alt={displayAd.alt} className={styles.courseImage} />
        </Suspense>

        {displayAd.ratings && (
          <div className={styles.rating}>
            {renderStars(displayAd.ratings.rating)}
            <span>{displayAd.ratings.rating} / 5</span>
          </div>
        )}

        <div className={styles.courseDetails}>
          <p><strong>{truncateTitle(displayAd.title)}</strong></p>
          <div className={styles.priceContainer}>
            {displayAd.oldPrice && <p className={styles.oldPrice}>De: {displayAd.oldPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>}
            {displayAd.newPrice && <p className={styles.newPrice}>Por: {displayAd.newPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>}
            {displayAd.newPrice && displayAd.oldPrice && <p className={styles.discount}>Desconto: {Math.round(((displayAd.oldPrice - displayAd.newPrice) / displayAd.oldPrice) * 100)}%</p>}
            {discount && <p className={styles.discount}>{discount}</p>}
          </div>

          {displayAd.discountCoupons.length > 0 && (
            <div className={styles.coupons}>
              <h2>Cupons de Desconto:</h2>
              <ul>
                {displayAd.discountCoupons.map((coupon, idx) => (
                  <li key={idx}>{truncateTitle(coupon, 23)}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </a>
    </div>
  );
};

export default CourseAd;