import React, { useState } from 'react';
import emailjs from 'emailjs-com'; // Para envio de emails
import Layout from '../../components/layout/';
import Breadcrumb from '../../components/breadcrumb';
import styles from '../../styles/ContactPage.module.css';  // Importando o CSS Module

const ContactPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Enviar o email usando emailjs
    emailjs.sendForm('service_id', 'template_id', e.target, 'user_id')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    // Limpar os campos do formulário
    setName('');
    setEmail('');
    setMessage('');
  };

  return (
    <Layout title='Contato - Minha Sorte' 
      description='Entre em contato com a nossa equipe para tirar suas dúvidas sobre resultados de loteria, fazer sugestões ou reportar problemas. Atendemos rapidamente e com eficiência.' 
      keywords='fale conosco, suporte, dúvidas, contato, ajuda, entre em contato, sugestões, feedback'>
      <div className={styles.contactContainer}>
        <Breadcrumb paths={[
          { href: '/', label: 'Home' },
          { href: '/contato', label: 'Contato' },
        ]} />
        <h2 style={{marginTop: '1rem'}}>Entre em contato conosco</h2>
        <form className={styles.contactForm} onSubmit={handleSubmit}>
          <input 
            className={styles.contactInput} 
            type="text" 
            placeholder="Nome" 
            value={name} 
            onChange={(e) => setName(e.target.value)} 
          />
          <input 
            className={styles.contactInput} 
            type="email" 
            placeholder="Email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
          />
          <textarea 
            className={styles.contactTextArea} 
            placeholder="Mensagem" 
            value={message} 
            onChange={(e) => setMessage(e.target.value)} 
          />
          <button className={styles.contactButton} type="submit">Enviar</button>
        </form>
      </div>
    </Layout>
  );
};

export default ContactPage;
