import React from 'react';
import Layout from '../../components/layout/';
import Breadcrumb from '../../components/breadcrumb';
import styles from '../../styles/PrivacyPolicy.module.css';  // Importando o CSS Module

const PrivacyPolicy = () => {
  return (
    <Layout title='Política de Privacidade - Minha Sorte' 
      description='Leia nossa política de privacidade e fique tranquilo com a segurança de suas informações pessoais. Transparência e segurança são nossos compromissos.' 
      keywords='política de privacidade, dados pessoais, proteção de dados, LGPD, GDPR, informações pessoais, cookies'>
      <div className={styles.policyContainer}>
        <Breadcrumb paths={[
          { href: '/', label: 'Home' },
          { href: '/politica-de-privacidade', label: 'Política de Privacidade' },
        ]} />
        
        <h1 className={styles.policyTitle}>Política de Privacidade</h1>
        
        <div className={styles.policyText}>
          <p>Esta política de privacidade descreve como o Minha Sorte coleta, utiliza, protege e divulga suas informações pessoais. Ao utilizar nosso site, você concorda com as práticas descritas nesta política.</p>
          
          <h2>Quais informações coletamos:</h2>
          <p>Não coletamos informações pessoais (nome, e-mail).</p>
          <p>Apenas coletamos dados de uso (páginas visitadas, dispositivos) para análise e melhoria do site.</p>
          
          <h2>Como utilizamos suas informações:</h2>
          <p>Para personalizar sua experiência no site.</p>
          <p>Para analisar o tráfego e melhorar nosso conteúdo.</p>
          
          <h2>Compartilhamento de informações:</h2>
          <p>Não compartilhamos suas informações pessoais com terceiros, exceto quando exigido por lei.</p>
          
          <h2>Segurança:</h2>
          <p>Implementamos medidas de segurança para proteger suas informações.</p>
          
          <h2>Alterações nesta política:</h2>
          <p>Podemos atualizar esta política periodicamente.</p>
          
          <h2>Contato:</h2>
          <p>Para qualquer dúvida, entre em contato conosco através de admin@minhasorte.com.br</p>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
