import OfertasAntecipadasBlackMobile from '../assets/ads/ofertas-antecipadas-black-amazon.png';
import PrimeOfertas from '../assets/ads/esquenta-ofertas-prime-black-friday.png';
import PrimeOfertasMobile from '../assets/ads/esquenta-ofertas-prime-black-friday-mobile.png';
import PrimeAssinaturaAnualOfertas from '../assets/ads/prime-assinatura-plano-anual.png';
import PrimeAssinaturaAnualOfertasMobile from '../assets/ads/prime-assinatura-plano-anual-mobile.png';
import Livros50OffOfertas from '../assets/ads/ofertas-livros-50-off.png';
import Livros50OffOfertasMobile from '../assets/ads/ofertas-livros-50-off-mobile.png';
import Casa30OffOfertas from '../assets/ads/ofertas-casa-30-off.png';
import Casa30OffOfertasMobile from '../assets/ads/ofertas-casa-30-off-mobile.png';
import Brinquedos25OffOfertas from '../assets/ads/ofertas-brinquedos-25-off.png';
import Brinquedos25OffOfertasMobile from '../assets/ads/ofertas-brinquedos-25-off-mobile.png';
import Oferta15OffNikeMeliuz from '../assets/ads/15-off-nike-meliuz.png';

export const adsAmazon = (numero: string | undefined) => [
  {
    imageDesktop: 'https://m.media-amazon.com/images/G/32/br-events/2024/BF24/WU/TrafficChannels/Associates/WarmUp/17498_BFWU24_Associates_2148x588.jpg',
    imageMobile: OfertasAntecipadasBlackMobile,
    link: 'https://amzn.to/3YWduSk',
    alt: 'Imagem laranja escrito esquenta de ofertas black friday, com uma mulher branca de cabelo castanho segurando uma caixa de produtos',
    label: 'ad:amazon:<<pre-black-friday>>:ofertas-antecipadas',
    page: `lotofacil:concurso:<<${numero}>>`
  },
  {
    imageDesktop: PrimeOfertas,
    imageMobile: PrimeOfertasMobile,
    alt: 'Imagem azul cheia de caixas da amazon escrito esquenta de ofertas pré black friday',
    link: 'https://amzn.to/3YRErGs',
    label: 'ad:amazon:<<pre-black-friday>>:prime-ofertas',
    page: `lotofacil:concurso:<<${numero}>>`
  },
  {
    imageDesktop: PrimeAssinaturaAnualOfertas,
    imageMobile: PrimeAssinaturaAnualOfertasMobile,
    alt: 'Imagem cheia de foto de filmes e séries com oferta de assinatura anual do prime video',
    link: 'https://amzn.to/3AwBfqt',
    label: 'ad:amazon:<<pre-black-friday>>:prime-assinatura-anual',
    page: `lotofacil:concurso:<<${numero}>>`
  },
  {
    imageDesktop: Livros50OffOfertas,
    imageMobile: Livros50OffOfertasMobile,
    alt: 'Imagem com fotos de livros famosos e escrito ofertas de livros 50% de desconto',
    link: 'https://amzn.to/40yJmgI',
    label: 'ad:amazon:<<pre-black-friday>>:livros-50-off',
    page: `lotofacil:concurso:<<${numero}>>`
  },
  {
    imageDesktop: Casa30OffOfertas,
    imageMobile: Casa30OffOfertasMobile,
    alt: 'Imagem com fotos de produtos de casa e escrito ofertas de items de casa e decoração 30% de desconto',
    link: 'https://amzn.to/3CgT9OE',
    label: 'ad:amazon:<<pre-black-friday>>:casa-30-off',
    page: `lotofacil:concurso:<<${numero}>>`
  },
  {
    imageDesktop: Brinquedos25OffOfertas,
    imageMobile: Brinquedos25OffOfertasMobile,
    alt: 'Imagem com fotos de brinquedos e escrito ofertas de brinquedos 25% de desconto',
    link: 'https://amzn.to/4fv6D7F',
    label: 'ad:amazon:<<pre-black-friday>>:brinquedos-25-off',
    page: `lotofacil:concurso:<<${numero}>>`
  },
]

export const adsShopee = (numero: string | undefined) => [
  {
    image: "https://down-br.img.susercontent.com/file/sg-11134201-7rd6s-lupk641ostlm7c.webp",
    link: "https://s.shopee.com.br/8znodlFfaH",
    title: "Mochila escolar feminina STITCH de grande capacidade para meninas e meninos/Bolsa de computador para estudante do ensino médio",
    label: 'ad:shopee:<<dia-a-dia>>:mochila-feminina-stitch',
    page: `lotofacil:concurso:<<${numero}>>`,
    alt: "Mochila escolar feminina STITCH",
    oldPrice: 171.00,
    newPrice: 94.00,
    discountCoupons: ["67% off", "1% OFF", "R$3 de desconto", "2% OFF"]
  },
  {
    image: "https://down-br.img.susercontent.com/file/cn-11134207-7r98o-lv0c99q5bsul2b.webp",
    link: "https://s.shopee.com.br/1g1DuJG2Ms",
    title: "Kiko Lip Gloss 6.5ML /3D Hydra Lipgloss /Water Gloss Hidratante / Longa Duração Não Desbotante",
    label: 'ad:shopee:<<dia-a-dia>>:kiko-lip-gloss',
    page: `lotofacil:concurso:<<${numero}>>`,
    alt: "Kiko Lip Gloss 6.5ML",
    oldPrice: 69.60,
    newPrice: 22.33,
    discountCoupons: ["68% off", "R$3 de desconto", "R$5 de desconto"],
  },
  {
    image: "https://down-br.img.susercontent.com/file/br-11134207-7r98o-lwyf4h6nymief6.webp",
    link: "https://s.shopee.com.br/1B4xJPUjgY",
    title: "Vestido Longo Feminino Midi com Fenda Lateral e detalhes luxo",
    label: 'ad:shopee:<<dia-a-dia>>:vestido-long-midi',
    page: `lotofacil:concurso:<<${numero}>>`,
    alt: "Vestido Longo Feminino",
    oldPrice: 68.00,
    newPrice: 60.99,
    discountCoupons: ["10% off", "5% de Cashback em Moedas"],
    additionalInfo: [
      "Frete grátis acima de R$19,00",
      "Envio nacional",
      "Parcelamento em até 12x de R$5,92"
    ],
    ratings: {
      rating: 4.8,
      reviews: 903,
      sold: 1500
    }
  },
  {
    image: "https://down-br.img.susercontent.com/file/br-11134207-7r98o-lpi1b64jptol8f.webp", // Exemplo de imagem do pisca-pisca
    link: "https://s.shopee.com.br/6pjK3qQq6s", // Link de exemplo para o pisca-pisca
    title: "Pisca Pisca 100 Leds USB Árvore Natal Decoração Colorido Led 10M Prova D'Agua Interno Bivolt",
    label: 'ad:shopee:<<festas>>:pisca-pisca-natal',
    page: `lotofacil:concurso:<<${numero}>>`,
    alt: "Pisca Pisca LED 100 LEDs",
    oldPrice: 24.99,
    newPrice: 19.99,
    discountCoupons: ["17% off", "Frete grátis com cupom"],
    additionalInfo: [
      "Frete grátis acima de R$19,00",
      "Envio nacional",
      "TAMANHOS: 10m 100 LEDs, 20m 200 LEDs, entre outros"
    ],
    ratings: {
      rating: 4.9,
      reviews: 2500,
      sold: 4700
    }
  },
  {
    image: "https://down-br.img.susercontent.com/file/br-11134207-7r98o-lymnuq0mfjpx9c.webp", // Substitua com a URL da imagem real do produto
    link: "https://s.shopee.com.br/9KQf2SKlfz", // Link de exemplo para o produto
    title: "Kit Jogo de Cozinha Silicone, Utensílios e Copo Suporte para Cozinheiro Cabo de Madeira MasterChef",
    label: 'ad:shopee:<<cozinha>>:kit-jogo-cozinha-masterchef',
    page: `lotofacil:concurso:<<${numero}>>`,
    alt: "Kit Jogo de Cozinha MasterChef",
    oldPrice: 59.90,
    newPrice: 19.80,
    discountCoupons: ["67% off", "Frete grátis com cupom"],
    additionalInfo: [
      "Frete grátis com cupom",
      "Chega amanhã comprando até às 23h59",
      "COR: 12-Peça-Aleatório, 5-Peça-Aleatório-Sem-Copo, 12-Peça-Preto/Chumbo/Cinza, 12-Peça-Rosa/Vermelho/Lilás, 12-Peça-Verd-Claro/Verd-Escuro, 5-Peça-Vermelho-Sem-Copo"
    ],
    ratings: {
      rating: 4.8,
      reviews: 3200,
      sold: 5900
    }
  },
  {
    image: "https://down-br.img.susercontent.com/file/br-11134207-7r98o-ls32k159tmwr89.webp",
    link: "https://s.shopee.com.br/10lX8NqbBo",
    title: "Penteadeira 50x30 Escrivaninha Suspensa Organizadora Maquiagens Quarto 100% MDF",
    label: 'ad:shopee:<<mobiliario>>:penteadeira-mdf',
    page: `lotofacil:concurso:<<${numero}>>`,
    alt: "Penteadeira 50x30 MDF",
    oldPrice: 99.90,
    newPrice: 96.90,
    discountCoupons: ["3% off", "Frete grátis com cupom"],
    additionalInfo: [
      "Frete grátis com cupom",
      "Chega amanhã comprando até às 23h59",
      "Material: 100% MDF de alta qualidade"
    ],
    ratings: {
      rating: 4.8,
      reviews: 821,
      sold: 1600
    }
  },
  {
    image: "https://down-br.img.susercontent.com/file/br-11134207-7r98o-lu969c1rem3k06.webp", // Substitua com a URL real da imagem
    link: "https://s.shopee.com.br/60AD5gzbTH", // Substitua com o link real
    title: "Kit Giovanna Baby Body Splash Classic Loção Hidratante Classic Sabonete Classic Desodorante Roll-On",
    label: 'ad:shopee:<<beleza>>:kit-giovanna-baby-classic',
    page: `lotofacil:concurso:<<${numero}>>`,
    alt: "Kit Giovanna Baby Classic",
    oldPrice: 145.89,
    newPrice: 129.53,
    discountCoupons: ["34% off", "Parcelamento em até 10x de R$5,85 no cartão"],
    additionalInfo: [
      "Chega amanhã comprando até às 23h59",
      "Opções de parcelamento disponíveis"
    ],
    ratings: {
      rating: 4.9,
      reviews: 712,
      sold: 1400
    }
  },
  {
    image: "https://down-br.img.susercontent.com/file/br-11134207-7qukw-ljktyu2mq07e11.webp", // Substitua com a URL real da imagem
    link: "https://s.shopee.com.br/7Uz0sVwCCD", // Substitua com o link real
    title: "Porta Temperos Condimentos com Potes Giratório ou fixo Inox OFF",
    label: 'ad:shopee:<<cozinha>>:porta-temperos-inox',
    page: `lotofacil:concurso:<<${numero}>>`,
    alt: "Porta Temperos Inox",
    oldPrice: 135.95,
    newPrice: 78.85,
    discountCoupons: ["42% off"],
    additionalInfo: [
      "Frete grátis com cupom",
      "Potes giratórios ou fixos, de inox resistente"
    ],
    ratings: {
      rating: 4.9,
      reviews: 676,
      sold: 1400
    }
  },
  {
    image: "https://down-br.img.susercontent.com/file/br-11134207-7r98o-lx9s12wqayduf1.webp", // Substitua com a URL real da imagem
    link: "https://s.shopee.com.br/6fPttAzGRW", // Substitua com o link real
    title: "Jogo Xícaras de Chá Café e Pires Coração Madeira Mesa Posta Cantinho do Café Dolce Gusto Nespresso",
    label: 'ad:shopee:<<cozinha>>:jogo-xicaras-coracao-madeira',
    page: `lotofacil:concurso:<<${numero}>>`,
    alt: "Jogo Xícaras Coração Madeira",
    oldPrice: 59.90,
    newPrice: 47.50,
    discountCoupons: ["21% off", "Combo disponível"],
    additionalInfo: [
      "Ideal para café, chá e para usar com máquinas Dolce Gusto ou Nespresso",
      "Material: Madeira e cerâmica",
      "Opções de combinação com pires e xícaras"
    ],
    ratings: {
      rating: 5.0,
      reviews: 328,
      sold: 621
    }
  },
  {
    image: "https://down-br.img.susercontent.com/file/0717fe00f09a2ef32de0e47501845889.webp", // Substitua com a URL real da imagem
    link: "https://s.shopee.com.br/9pMvfgR607", // Substitua com o link real
    title: "Escova Secadora Alisador Elétrica Quente Cabelo Com 3 Em1 Hair Styler",
    label: 'ad:shopee:<<beleza>>:escova-secadora-hair-styler',
    page: `lotofacil:concurso:<<${numero}>>`,
    alt: "Escova Secadora Hair Styler",
    oldPrice: 164.99,
    newPrice: 44.99,
    discountCoupons: ["Desconto de até 73%"],
    additionalInfo: [
      "Escova 3 em 1: secadora, alisadora e modeladora",
      "Ideal para diferentes tipos de cabelo",
      "Design moderno e prático para uso diário"
    ],
    ratings: {
      rating: 4.5,
      reviews: 60500,
      sold: 112300
    }
  },
  {
    image: "https://down-br.img.susercontent.com/file/br-11134207-7r98o-lvl1humlnbgp91.webp", // Substitua com a URL real da imagem
    link: "https://s.shopee.com.br/7AMAUv7ct2", // Substitua com o link real
    title: "Kit 12 Pares de Meias Soquete Unissex Cano Curto",
    label: 'ad:shopee:<<moda>>:kit-meias-soquete-unissex',
    page: `lotofacil:concurso:<<${numero}>>`,
    alt: "Kit Meias Soquete Unissex",
    oldPrice: 69.90,
    newPrice: 25.97,
    discountCoupons: ["84% off", "5% OFF com cupons de loja"],
    additionalInfo: [
      "Kit com 12 pares de meias soquete, cano curto",
      "Disponível em várias cores e tamanhos",
      "Confortáveis e ideais para o uso diário"
    ],
    ratings: {
      rating: 4.9,
      reviews: 18300,
      sold: 37000
    },
    flashSale: {
      startsAt: "22:00, 7 de nov",
      icon: "flash sale"
    }
  },
  {
    image: "https://down-br.img.susercontent.com/file/br-11134207-7r98o-lom21qr0noef1b.webp", // Substitua com a URL real da imagem
    link: "https://s.shopee.com.br/8znogQSLoK", // Substitua com o link real
    title: "Conjunto Degradê Academia Fitness Legging Cintura Alta e Bojo Conjunto Feminino Oferta",
    label: 'ad:shopee:<<moda>>:conjunto-degrade-academia',
    page: `lotofacil:concurso:<<${numero}>>`,
    alt: "Conjunto Fitness Degradê",
    oldPrice: 85.90,
    newPrice: 56.90,
    discountCoupons: ["34% off", "Leve 2, Pague Menos"],
    additionalInfo: [
      "Conjunto feminino com legging de cintura alta e bojo",
      "Design degradê e material confortável para prática de atividades físicas",
      "Disponível em várias cores e tamanhos"
    ],
    ratings: {
      rating: 4.7,
      reviews: 933,
      sold: 2100
    }
  }
];

export const hotmartAds = (numero: string | undefined) => [
  {
    image: "https://static-media.hotmart.com/Z_F-K2JXxfce4kIZkmQF43WgWOA=/filters:background_color(white)/hotmart/product_pictures/6f187266-f7bc-455c-86b2-001d5c3fbb09/cursohotmart.png",
    link: "https://go.hotmart.com/L70630408K",
    title: "Curso de Manicure e Pedicure Iniciante",
    label: 'ad:hotmart:<<curso>>:manicure-pedicure-iniciante-fabi-cardoso', 
    page: `lotofacil:concurso:<<${numero}>>`,
    alt: "Curso de Manicure e Pedicure",
    oldPrice: 124.41, 
    newPrice: 99.00,
    discountCoupons: []
  },
  {
    image: "https://static-media.hotmart.com/v1OcxFzAAMVYaQVy6yl4jWWQjIU=/filters:background_color(white)/hotmart/product_pictures/ae53f878-7ac7-472b-b9cf-982ee0ca718a/CpiadeOpenHouse4.png",
    link: "https://go.hotmart.com/S96481313C",
    title: "Curso de Unhas (Fibra + Esmaltação em Gel + Manicure e Pedicure)",
    label: 'ad:hotmart:<<curso>>:curso-unhas-fibra-esmaltacao-hellen-barbosa', 
    page: `lotofacil:concurso:<<${numero}>>`,
    alt: "Curso de Unhas (Fibra + Esmaltação em Gel + Manicure e Pedicure)",
    oldPrice: 73.85, 
    newPrice: 67.00,
    discountCoupons: []
  },
  {
    image: "https://static-media.hotmart.com/Xe2qw-u9rj49x0V9a7r8mffULHA=/filters:background_color(white)/hotmart/product_pictures/6a8864b1-25ab-40c9-a537-35457471ca27/Hotmart001.png",
    link: "https://go.hotmart.com/R71606935E",
    title: "Curso Depilação Profissional - Oficial",
    label: 'ad:hotmart:<<curso>>:depilacao-profissional-priscilla-fidelis', 
    page: `lotofacil:concurso:<<${numero}>>`,
    alt: "Curso Depilação Profissional - Oficial",
    oldPrice: 116.16, 
    newPrice: 97.00,
    discountCoupons: []
  },
  {
    image: "https://static-media.hotmart.com/2cSARpacSYlk5w6VL16eMxBwWfc=/filters:background_color(white)/hotmart/product_pictures/e59007c8-851e-47b9-aef3-dbb4ab686bbc/Lash_LiftingCapa1.png",
    link: "https://go.hotmart.com/J96481610G",
    title: "Curso de Lash Lifting + Extensão de Cílios + Bônus",
    label: 'ad:hotmart:<<curso>>:lash-lifting-pauline-mocelin', 
    page: `lotofacil:concurso:<<${numero}>>`,
    alt: "Curso de Lash Lifting + Extensão de Cílios + Bônus",
    oldPrice: 397.00, 
    newPrice: 197.00,
    discountCoupons: []
  }
]

export const appOfertasAds = (numero: string | undefined) => [
  {
    image: Oferta15OffNikeMeliuz,
    link: "https://www.meliuz.com.br/i/ref_55eb21ae?ref_source=81",
    title: "Meliuz: Até 30% Off nas compras",
    label: 'ad:hotmart:<<app-ofertas>>:meliuz', 
    page: `lotofacil:concurso:<<${numero}>>`,
    alt: "Imagem rosa do aplicativo meliuz que tem ofertas e cupons de desconto",
    discountCoupons: ['5% Off Centauro', '4% Off Consul']
  },
  {
    image: "https://cuponomia-a.akamaihd.net/img/logo-fb.png",
    link: "https://www.cuponomia.com.br/ref/f9f75b73d7a7?asid=e7d1b19b-f20d-493f-b6ba-c63ab53cfda1",
    title: "Cuponomia: Até 30% Off nas compras",
    label: 'ad:hotmart:<<app-ofertas>>:cuponomia', 
    page: `lotofacil:concurso:<<${numero}>>`,
    alt: "Imagem roxa do aplicativo cuponomia que tem ofertas e cupons de desconto",
    discountCoupons: ['5% Off Adidas', '8% Off Natura']
  }
]

