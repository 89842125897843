import React from 'react';
import { Link } from 'react-router-dom';
import styles from './header.module.css';
import PrivacyControl from '../PrivacyControl';


const Header = () => {
  return (
    <header className={styles.header}>
      <Link to="/" className={styles.headerLink}>
          Resultados Loteria
      </Link>
      <PrivacyControl />
    </header>
  );
}

export default Header;
