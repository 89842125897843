import React, { memo, Suspense, useCallback, useEffect, useState } from 'react';
import Layout from '../../components/layout';
import { getLotofacilContest } from '../../utils/contest';
import styles from '../../styles/Contest.module.css';
import { LotofacilResult } from '../../utils/interfaces';
import Breadcrumb from '../../components/breadcrumb';
import { useParams } from 'react-router-dom';
import { arrayRange, cleanUrlParam, generateFakeArray, removeHtmlExtension, sum } from '../../utils/functions';
import { getDayOfWeek, formatDate } from '../../utils/date';
import { adsAmazon, adsShopee, appOfertasAds, hotmartAds } from '../../utils/ads';
import ProductAdBanner from '../../components/ProductAdProps';
import CourseAd from '../../components/CourseAd';

const AdBanner = React.lazy(() => import('../../components/AdBanner'));
const LotofacilResume = React.lazy(() => import('../../components/seo/lotofacil'));

const SingleContest = memo((): JSX.Element => {
  const { numero } = useParams();

  const validNumber = cleanUrlParam(String(numero))

  const [contest, setContest] = useState<LotofacilResult>();
  const [loading, setLoading] = useState<boolean>(true);
  const [dataFetched, setDataFetched] = useState<boolean>(false);

  useEffect(() => {
    if (!!validNumber?.length) {
      const fetchData = async () => {
        try {
          const result: LotofacilResult = await getLotofacilContest(parseInt(validNumber));
          setContest(result);
          setLoading(false);
        } catch (error) {
          setDataFetched(true);
        }
      };

      fetchData();
    }
  }, [validNumber]);

  const paths = [
    { href: '/', label: 'Home' },
    { href: `/lotofacil/concurso/${validNumber}`, label: `Lotofácil Concurso ${validNumber}` }
  ];

  const formatCash = useCallback((value: number | undefined) => {
    if (!value) {
      return 'R$ 00,00';
    }
    return value?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  }, []);

  const formatQuantity = useCallback((number: number) => {
    return new Intl.NumberFormat('pt-BR').format(number);
  }, []);

  const fakeArrayResul: number[] = generateFakeArray(15)
  const fakeArrayPrize: number[] = generateFakeArray(5)
  const arrayOfWeekDraw: number[] = arrayRange((Number(validNumber) - 7), (Number(validNumber) - 1), 1)

  const getContestWeekDay = contest?.dataApuracao ? getDayOfWeek(contest?.dataApuracao) : 'carregando'
  const getContestFormatDate = contest?.dataApuracao ? formatDate(contest?.dataApuracao) : '00 de carregando de 0000'

  const title = `Resultado da Lotofácil ${validNumber} - Confira Números Sorteados e Ganhadores`
  const description = `Confira o resultado do concurso Lotofácil ${validNumber} de hoje ${getContestWeekDay.toLocaleLowerCase()}, ${getContestFormatDate}. Veja os números sorteados, ganhadores, e detalhes da premiação. Resultados de ontem e anteriores da Lotofácil também disponíveis. Acesse agora para mais informações!`
  const keywords = `lotofácil, resultado lotofácil, lotofácil concurso ${validNumber}, resultado concurso lotofácil ${validNumber}, números sorteados lotofácil, lotofácil de hoje, prêmio lotofácil, ganhadores lotofácil, sorteio lotofácil, loteria caixa, resultados loterias caixa, apostas lotofácil, estatísticas lotofácil, lotofácil online, bolão lotofácil, dicas lotofácil, estratégia lotofácil, como jogar na lotofácil, conferir resultado lotofácil, mega sena, quina, loto fácil, loteria federal, sorteios lotofácil, acumulado lotofácil, lotofácil acumulada, lotofácil estatísticas, lotofácil premiação, lotofácil últimos resultados, ganhadores concurso lotofácil, números sorteados lotofácil, resultados da lotofácil, como ganhar na lotofácil`
  const checkIfIsLoadingOrContestNotExist = loading && !dataFetched ? 'Carregando...' : 'Não Sorteado'

  const handleCopy = (validNumber: string, listaDezenas: string[]) => {
    const validNumbers = listaDezenas.map(num => Number(num)).join(", ");
    const textoParaCopiar = `Resultado Lotofácil Concurso ${validNumber}: ${validNumbers}`;

    navigator.clipboard.writeText(textoParaCopiar)
      .then(() => {
        alert('Números copiados para a área de transferência!');
      })
      .catch(err => {
        alert('Falha ao copiar números: ' + err);
      });
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: title,
        url: window.location.href
      })
        .then(() => {
          console.log("Compartilhado com sucesso!");
        })
        .catch((error) => {
          console.error("Erro ao compartilhar:", error);
        });
    } else {
      alert('Seu navegador não suporta a API de compartilhamento.');
    }
  };

  return (
    <>
      <Layout
        title={title}
        description={description}
        keywords={keywords}
        link={`lotofacil/concurso/${validNumber}`}>
        <div className='lotofacil-page-container' style={{ padding: '1rem' }}>
          <Breadcrumb paths={paths} />
          <article>
            <div className={styles.titleContainer}>
              <h1 className={styles.contestPageTitle}>Resultado da Lotofácil {validNumber}</h1>
              <button
                className={styles.shareButton}
                onClick={handleShare}
                title="Compartilhar"
              >
                📤 Compartilhar
              </button>
            </div>

            {(!contest?.dataApuracao && dataFetched) && (<a href={`/`} style={{ color: 'var(--dark-burgundy)', fontSize: '1.5rem', fontWeight: 'bold' }}>Aviso: O sorteio ainda não foi realizado. <br /> O resultado do sorteio estará disponível a partir das 21h30 da data do sorteio. <br /> Veja os resultados dos sorteios da semana.</a>)}
            <p>O sorteio do concurso <strong>Lotofácil {validNumber}</strong> foi realizado na <strong>{getContestWeekDay}, {getContestFormatDate}</strong>, a partir das 20h (horário de Brasília), no <strong>Espaço da Sorte</strong>, em São Paulo. A premiação para os acertadores dos 15 números sorteados começou a partir de <strong>R$ 1,7 milhão</strong>.</p>
            <p><strong>Lotofácil</strong> é uma das modalidades mais populares da <strong>Caixa Econômica Federal</strong>. Este concurso também premiou aqueles que acertaram 11, 12, 13 ou 14 números, com valores proporcionais aos acertos.</p>
          </article>

          <Suspense fallback={<div>Carregando o Conteúdo...</div>}>
            <CourseAd ads={hotmartAds(validNumber)} />
          </Suspense>

          <article className={styles.drawnNumbersContainer}>
            <h2 id='validNumbers-sorteados'>Números Sorteados no Concurso Lotofácil {validNumber}:</h2>
            <p>Os números sorteados no concurso <strong>Lotofácil {validNumber}</strong>, realizado em <strong>{getContestFormatDate}</strong>, são:</p>
            <div className={styles.drawnNumbersWrapper}>
              {loading ? (
                fakeArrayResul.map((_, index) => (
                  <div
                    className={styles.drawnNumbers}
                    key={index}
                    style={{ width: '50px', height: '50px', backgroundColor: '#e0e0e0' }}  // Tamanho fixo e cor de fundo para carregamento
                  >
                    ?
                  </div>
                ))
              ) : (
                contest?.listaDezenas.map((num) => (
                  <div
                    className={styles.drawnNumbers}
                    key={num}
                    style={{ width: '50px', height: '50px' }}  // Tamanho fixo para números
                  >
                    {num}
                  </div>
                ))
              )}
            </div>

            <button
              onClick={() => handleCopy(validNumber || '', contest?.listaDezenas || [])}
              className={styles.copyButton}
            >
              Copiar Números
            </button>
          </article>
          <Suspense fallback={<div>Carregando o Conteúdo...</div>}>
            <CourseAd title="🤑 Precisa economizar? Baixe o App! 💰" discount='Até 30% OFF nas compras' ads={appOfertasAds(validNumber)} />
          </Suspense>
          <article>
            <h2 id='apostas-premiadas'>Confira quantas apostas foram premiadas no concurso {validNumber}:</h2>
            <div className={styles.contestTable}>
              <table>
                <thead>
                  <tr>
                    <th>Pontos</th>
                    <th>Faixa</th>
                    <th>Ganhadores</th>
                    <th>Prêmio (R$)</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? fakeArrayPrize.map((_, index) =>
                    <tr key={index}>
                      <td>{checkIfIsLoadingOrContestNotExist}</td>
                      <td>{checkIfIsLoadingOrContestNotExist}</td>
                      <td>{checkIfIsLoadingOrContestNotExist}</td>
                      <td>{checkIfIsLoadingOrContestNotExist}</td>
                    </tr>
                  ) :
                    contest?.listaRateioPremio && contest.listaRateioPremio.map(({ descricaoFaixa, faixa, numeroDeGanhadores, valorPremio }) =>
                      <tr key={descricaoFaixa}>
                        <td>{descricaoFaixa}</td>
                        <td>{faixa}º</td>
                        <td>{formatQuantity(numeroDeGanhadores)}</td>
                        <td>{formatCash(valorPremio)}</td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
          </article>
          <Suspense fallback={<div>Carregando o Conteúdo...</div>}>
            <ProductAdBanner ads={adsShopee(validNumber)} />
          </Suspense>
          <article>
            <h2 id='cidades-ganhadoras'>Cidades Ganhadoras no Concurso Lotofácil {validNumber}:</h2>
            <div className={styles.contestTable}>
              <table>
                <thead>
                  <tr>
                    <th>Acerto</th>
                    <th>Ganhadores</th>
                    <th>Município</th>
                    <th>UF</th>
                  </tr>
                </thead>
                <tbody>
                  {!loading && contest?.listaMunicipioUFGanhadores.length === 0 && (
                    <tr>
                      <td>0</td>
                      <td>NÃO HOUVE ACERTADORES DE 15 DEZENAS NESSE CONCURSO</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                  )}
                  {loading ? fakeArrayPrize.map((_, index) =>
                    <tr key={index}>
                      <td>{checkIfIsLoadingOrContestNotExist}</td>
                      <td>{checkIfIsLoadingOrContestNotExist}</td>
                      <td>{checkIfIsLoadingOrContestNotExist}</td>
                      <td>{checkIfIsLoadingOrContestNotExist}</td>
                    </tr>
                  ) :
                    contest?.listaMunicipioUFGanhadores
                    && contest.listaMunicipioUFGanhadores
                      .map(({ ganhadores, municipio, uf }) =>
                        <tr key={municipio}>
                          <td>15 pontos</td>
                          <td>{ganhadores}</td>
                          <td>{!!municipio.length ? municipio : 'NÃO IDENTIFICADO'}</td>
                          <td>{uf}</td>
                        </tr>
                      )}
                </tbody>
              </table>
            </div>
          </article>
          <Suspense fallback={<div>Carregando o Conteúdo...</div>}>
            <ProductAdBanner ads={adsShopee(validNumber)} />
          </Suspense>

          <article>
            <h2 id='receber-premio'>Como Receber o Prêmio da Lotofácil</h2>
            <p>Os ganhadores da <strong>Lotofácil</strong> podem resgatar seus prêmios em casas lotéricas credenciadas ou nas agências da <strong>Caixa Econômica Federal</strong>. Para prêmios acima de <strong>R$ 1.903,98</strong>, o resgate deve ser feito exclusivamente nas agências da Caixa, com apresentação de documento de identidade original com CPF e o recibo de aposta premiado.</p>
            <p>Há uma regra importante: para prêmios iguais ou superiores a <strong>R$ 10 mil</strong>, o pagamento será feito em até dois dias após a apresentação do bilhete premiado em uma agência da Caixa. Fique atento aos prazos para garantir o seu prêmio!</p>
          </article>

          <article>
            <h2 id='proximo-sorteio'>Próximo Sorteio: Lotofácil {sum(validNumber, 1)}</h2>
            <p>O <a href={`/lotofacil/concurso/${sum(validNumber, 1)}`}>próximo sorteio da <strong>Lotofácil</strong> será o de número <strong>{sum(validNumber, 1)}</strong> {contest?.dataProximoConcurso && `no dia ${contest?.dataProximoConcurso}`}</a>. Os sorteios da Lotofácil ocorrem regularmente três vezes por semana, nas <strong>segundas</strong>, <strong>quartas</strong> e <strong>sextas-feiras</strong>, sempre às <strong>20 horas</strong>, salvo em datas especiais previamente comunicadas pela <strong>Caixa Econômica Federal</strong>.</p>
          </article>

          <article className={styles.weekDraw}>
            <h2 id='sorteios-semana'>Resultados da Lotofácil de Ontem e Sorteios da Semana:</h2>
            {
              arrayOfWeekDraw.reverse().map((contest: any) => (
                <p><a href={`/lotofacil/concurso/${contest}`}>Resultado Lotofácil Concurso {contest}</a></p>
              ))
            }
          </article>
          <Suspense fallback={<div>Carregando o Conteúdo...</div>}>
            <LotofacilResume />
          </Suspense>
        </div>
      </Layout>
    </>
  )
})

export default SingleContest;