import { arrayRange } from "./functions";
import { LotofacilContest, LotofacilResult } from "./interfaces";

export const getAllLotofacilContest = async () => {

  let contest: LotofacilContest = {
    numero: 0
  }

  const cacheKey = 'lotofacilContests'// Define a clear cache key

  // Check for cached data in localStorage
  const cachedData = sessionStorage.getItem(cacheKey);
  if (cachedData) {
    try {
      const parsedData = JSON.parse(cachedData);

      return parsedData; // Return cached data
    } catch (error) {
      console.error('Error parsing cached LotoFácil array of contest data:', error);
      // Consider clearing the cache or handling errors differently
    }
  }

  const fallbackUrl2 = 'https://api.guidi.dev.br/loteria/lotofacil/ultimo';
  try {

    const fallbackResponse2 = await fetch(fallbackUrl2);
    if (!fallbackResponse2.ok) {
      throw new Error('Guidi.dev.br Lotofácil API request failed');
    }
    const fallbackData2: LotofacilResult = await fallbackResponse2.json();


    contest = fallbackData2
  } catch (error) {

    const cefUrl = 'https://servicebus2.caixa.gov.br/portaldeloterias/api/home/ultimos-resultados';
    try {
      // Fallback: Caixa Econômica Api
      const cefResponse = await fetch(cefUrl);
      if (!cefResponse.ok) {
        throw new Error('CEF "ultimos-resultados" API request failed');
      }
      const cefData: { lotofacil: { numeroDoConcurso: number } } = await cefResponse.json();
      contest.numero = cefData.lotofacil.numeroDoConcurso


    } catch (error) {
      throw new Error(`Failed to fetch LotoFácil contest: ${error}`);
    }
  }

  const contestsQuantity = 5
  const arrayOfContest = arrayRange((contest?.numero - contestsQuantity), contest?.numero, 1)

  sessionStorage.setItem(cacheKey, JSON.stringify(arrayOfContest.reverse()));

  return !!arrayOfContest.length ? arrayOfContest : [];
}

export const getLotofacilContest = async (contestNumber: number): Promise<LotofacilContest | {}> => {
  const cacheKey = `lotofacil-${contestNumber}`; // Define a clear cache key

  // Check for cached data in localStorage
  const cachedData = localStorage.getItem(cacheKey);
  if (cachedData) {
    try {
      const parsedData = JSON.parse(cachedData);

      return parsedData; // Return cached data
    } catch (error) {
      console.error('Error parsing cached LotoFácil contest data:', error);
    }
  }

  try {
    const guidiDevResponse = await fetch(
      `https://api.guidi.dev.br/loteria/lotofacil/${contestNumber}`
    );

    if (!guidiDevResponse.ok) {
      throw new Error('Fallback API request failed');
    }

    const guidiDevData: LotofacilContest = await guidiDevResponse.json();

    localStorage.setItem(cacheKey, JSON.stringify({ ...guidiDevData, timestamp: Date.now() })); // Add timestamp for expiration (optional)

    return !!guidiDevData.numero ? guidiDevData : {};
  } catch (error) {

    try {
      // Fallback: Caixa Econômica Api
      const cefResponse = await fetch(
        `https://servicebus2.caixa.gov.br/portaldeloterias/api/lotofacil/${contestNumber}`
      );

      if (!cefResponse.ok) {
        throw new Error('CEF API request failed');
      }

      const cefData: LotofacilContest = await cefResponse.json();

      localStorage.setItem(cacheKey, JSON.stringify({ ...cefData, timestamp: Date.now() })); // Add timestamp for expiration (optional)

      return !!cefData.numero ? cefData : {};
    } catch (error) {
      throw new Error(`Failed to fetch LotoFácil contest: ${error}`);
    }
  }
};