import React, { lazy, Suspense, useCallback } from 'react';
import { useEffect, useState } from 'react'

import Layout from '../../components/layout';

import styles from '../../styles/Home.module.css'
import { getAllLotofacilContest } from '../../utils/contest';
import { generateFakeArray } from '../../utils/functions';

import lotofacilLogo from '../../assets/lotofacil-logo.jpg';


const LazyImage = lazy(() => import('../../components/lazyImage'));


const LotofacilResume = React.lazy(() => import('../../components/seo/lotofacil'));


const Page = () => {
  const [contests, setContests] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchContests = useCallback(async () => {
    const fetchedContests = await getAllLotofacilContest();
    setContests(fetchedContests);
    setLoading(false); // Set loading to false after data is fetched
  }, []);

  useEffect(() => {
    fetchContests();
  }, [fetchContests]);

  const fakeArray: number[] = generateFakeArray(6)

  const description = 'Confira os resultados de todos os concursos da Lotofácil. Informações atualizadas sobre os sorteios de hoje, ontem e do mês inteiro, incluindo Segunda, Terça, Quarta, Quinta, Sexta e Sábado.'
  const keywords = 'resultados lotofácil, concursos lotofácil, sorteios lotofácil, lotofácil hoje, resultados diários lotofácil'

  return (
    <Layout title='Últimos Resultados Concursos da Lotofácil' description={description} keywords={keywords}>
      <div style={{ padding: '1rem' }}>

        <h1 className="subtitle">Resultado de Todos os Concursos da Loteria:</h1>
        <p>Informações de todos os sorteios/concursos da Lotofácil de hoje, ontem e do mês inteiro (Segunda, Terça, Quarta, Quinta, Sexta e Sábado)</p>

        <section className={styles.contestsContainer}>
          <h2>Últimos resultados da Lotofácil</h2>
          <div>
            {loading ? fakeArray.map((_, index) => (
              <div className={styles.contestsResultLink}>
                <Suspense fallback='Imagem Carregando...'>
                  <LazyImage src={lotofacilLogo} alt="Imagem roxa, escrita lotofácil em branco com a fonte em negrito, com o símbolo de uma flor ao lado do texto" />
                </Suspense>
                <p>
                  <span>Resultado Concurso Lotofácil ...</span>
                  <span>Confira os Números Sorteados e Ganhadores</span>
                </p>
              </div>
            )) :
              contests.map((contest: any) => (
                <a href={`/lotofacil/concurso/${contest}`} className={styles.contestsResultLink}>
                  <Suspense fallback='Imagem Carregando...'>
                    <LazyImage src={lotofacilLogo} alt="Imagem roxa, escrita lotofácil em branco com a fonte em negrito, com o símbolo de uma flor ao lado do texto" />
                  </Suspense>
                  <p>
                    <strong>Resultado Lotofácil {contest}</strong>
                    <span>Confira os Números Sorteados e Ganhadores</span>
                  </p>
                </a>
              ))}
          </div>
        </section>
        <Suspense fallback={<div>Carregando o Conteúdo...</div>}>
          <LotofacilResume />
        </Suspense>
      </div>
    </Layout>
  )
}

export default Page;