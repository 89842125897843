import React from 'react';
import styles from '../../styles/Breadcrumb.module.css';

interface BreadcrumbProps {
  paths: { href: string; label: string }[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ paths }) => {

  return (
    <nav aria-label="breadcrumb">
      <ol className={styles.breadcrumb}>
        {paths.map((path, index) => (
          <li key={index} className={styles.breadcrumbItem}>
            {index === paths.length - 1 ? (
              <span>{path.label}</span>
            ) : (
              <a href={path.href}>{path.label}</a>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;