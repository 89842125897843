export function getDayOfWeek(brazilianDate: string): string {
    // Array with the names of the days of the week in English
    const daysOfWeek = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
  
    // Format the date to "YYYY-MM-DD" format
    const dateParts = brazilianDate.split('/');
    const formattedDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
  
    // Get the index of the day of the week (0 for Sunday, 1 for Monday, etc.)
    const dayOfWeekIndex = formattedDate.getDay();
  
    // Return the name of the day of the week
    return daysOfWeek[dayOfWeekIndex];
}


export function formatDate(brazilianDate: string): string {
    // Array with the full names of the months in Portuguese
    const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  
    // Format the date to "YYYY-MM-DD" format
    const dateParts = brazilianDate.split('/');
    const formattedDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
  
    // Get the day, month, and year components
    const day = formattedDate.getDate();
    const monthIndex = formattedDate.getMonth();
    const year = formattedDate.getFullYear();
  
    // Get the full month name
    const monthName = months[monthIndex];
  
    // Return the formatted date string
    return `${day} de ${monthName} de ${year}`;
}