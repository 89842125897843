import React from 'react';
import Layout from '../../components/layout/';
import Breadcrumb from '../../components/breadcrumb';
import styles from '../../styles/AboutPage.module.css';  // Importando o CSS Module

const AboutPage = () => {
  return (
    <Layout title='Sobre Nós - Minha Sorte' description='Somos especialistas em resultados de loteria! Oferecemos informações precisas, ferramentas úteis e um histórico completo de sorteios.' keywords='nossa empresa, apostas online, resultados de sorteios, Minha Sorte, equipe, missão, sobre nós'>
      <div className={styles.aboutContainer}>
        <Breadcrumb paths={[
          { href: '/', label: 'Home' },
          { href: '/sobre-nos', label: 'Sobre Nós' },
        ]} />
        
        <div className={styles.aboutSection}>
          <h2 className={styles.aboutTitle}>Quem Somos</h2>
          <p className={styles.aboutText}>
            Somos apaixonados por loterias e queremos oferecer a você a experiência mais completa e confiável para acompanhar os resultados dos seus jogos favoritos. Nosso objetivo é fornecer informações precisas e atualizadas, de forma clara e transparente.
          </p>
        </div>

        <div className={styles.aboutSection}>
          <h2 className={styles.aboutTitle}>Nossa Missão</h2>
          <p className={styles.aboutText}>
            Nossa missão é ser a principal fonte de informação sobre resultados de loteria no Brasil. Buscamos oferecer um serviço de qualidade, com dados confiáveis e ferramentas úteis para nossos usuários.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;
