import React from 'react';
import Layout from '../../components/layout/';
import Breadcrumb from '../../components/breadcrumb';
import styles from '../../styles/TermsOfUse.module.css';  // Importando o CSS Module

const TermsOfUse = () => {
  return (
    <Layout title='Termos de Uso - Minha Sorte' 
      description='Consulte nossos termos de uso e entenda os direitos e responsabilidades ao utilizar nosso site. Leia atentamente antes de continuar.' 
      keywords='termos de uso, condições de uso, contrato de uso, acordo de usuário, termos e condições, aviso legal, direitos autorais, privacidade, segurança'>
      
      <div className={styles.termsContainer}>
        <Breadcrumb paths={[
          { href: '/', label: 'Home' },
          { href: '/termos-de-uso', label: 'Termos de Uso' },
        ]} />
        
        <h1 className={styles.termsTitle}>Termos de Uso</h1>
        
        <div className={styles.termsText}>
          <p>Ao utilizar o Minha Sorte, você concorda com os seguintes termos e condições:</p>
          
          <h2>Uso do site:</h2>
          <p>O site é destinado apenas para fins informativos.</p>
          <p>Não garantimos a precisão dos resultados apresentados.</p>
          <p>O uso do site é por sua conta e risco.</p>

          <h2>Proibições:</h2>
          <p>É proibido utilizar o site para fins ilegais ou prejudiciais.</p>
          <p>É proibido copiar ou distribuir o conteúdo do site sem autorização.</p>

          <h2>Isenção de responsabilidade:</h2>
          <p>Não nos responsabilizamos por quaisquer danos causados pelo uso do site.</p>

          <h2>Alterações nos termos:</h2>
          <p>Podemos alterar estes termos a qualquer momento.</p>

          <h2>Lei aplicável:</h2>
          <p>Estes termos são regidos pelas leis do Brasil.</p>
        </div>
      </div>
    </Layout>
  );
};

export default TermsOfUse;
